
import {
  Component,
  Prop,
  Vue,
  Watch,
} from 'vue-property-decorator';
import Message from '@/components/mixins/Message.vue';
import ActionButton from '@/components/Button/Button.vue';
import eventBus from '@/event-bus';
import {
  DossierTypeBulkAction,
  DossierTypeListBulkAction,
  WorkflowBulkAction,
} from '@/types/ListTypes';
import objectHasProperties from '@/helpers/objectHelpers';
import { urlHashToArray } from '@/helpers/converters/url';
import { isEmptyArray } from '@/helpers/arrayHelpers';
import downloadBase64 from '@/helpers/files';

type GraphqlOperations = string;

type SelectedIds = Array<number>;

interface GraphqlVariables {
  [key: string]: string|number|Array<number|string>|GraphqlVariables,
}

@Component({
  name: 'ListBulkActions',
  computed: {
    eventBus() {
      return eventBus;
    },
  },
  components: {
    ActionButton,
  },
})
export default class ListBulkActions extends Vue {
  @Prop({ required: true })
  private readonly actions!: Array<DossierTypeBulkAction|WorkflowBulkAction|DossierTypeListBulkAction>;

  @Prop()
  private readonly blockId?: number;

  @Prop()
  private readonly dossierId?: number;

  @Prop()
  private readonly dossierTypeSlug?: string;

  @Prop()
  private readonly navigationSlug?: string;

  @Prop({ required: true })
  private readonly selectedIds!: SelectedIds;

  protected bulkActions: Array<DossierTypeBulkAction|DossierTypeListBulkAction> = [];

  protected workflowBulkAction: Array<WorkflowBulkAction> = []

  private loading = false;

  protected mounted(): void {
    this.setActions();
  }

  @Watch('actions')
  private setActions(): void {
    this.bulkActions = [];
    this.workflowBulkAction = [];

    this.actions.forEach((action) => {
      if (objectHasProperties<WorkflowBulkAction>(action as WorkflowBulkAction,
        ['dossierIds', 'name', 'errorMessage', 'successMessage', 'workflowId', 'action_type'])
      ) {
        this.workflowBulkAction.push(action as WorkflowBulkAction);
      } else if (objectHasProperties<DossierTypeBulkAction|DossierTypeListBulkAction>(action as DossierTypeBulkAction|DossierTypeListBulkAction,
        ['label', 'id', 'action_type'])
      ) {
        this.bulkActions.push(action as DossierTypeBulkAction);
      } else if (!isEmptyArray(action)) {
        throw new Error('Bulkactions doesn\'t match one of the types');
      }
    });
  }

  private allSelectedApplyToButton(ids: Array<number>): boolean {
    return this.selectedIds.length > 0
      && (this.selectedIds as Array<number>).every((element) => ids.includes(element));
  }

  @Watch('loading')
  private emitLoading() {
    this.$emit('update:loading', this.loading);
  }

  private getNavigationSlug(): string {
    return this.navigationSlug ?? this.$route.params.navigationSlug;
  }

  private getDossierTypeTag(): string {
    return this.dossierTypeSlug ?? this.$route.params.dossierTypeSlug;
  }

  private executeWorkflowAction(workflowAction: WorkflowBulkAction, transitionName: string) {
    const operation: GraphqlOperations = 'dossier-workflow-bulk-transition-dossiers';
    const variables: GraphqlVariables = {
      workflowId: workflowAction.workflowId,
      selectedIds: this.selectedIds,
      navigationSlug: this.getNavigationSlug(),
      dossierTypeSlug: this.getDossierTypeTag(),
      transitionName,
    };

    this.executeAction(operation, variables, workflowAction.metadata?.forceRerender ?? true);
    eventBus.$emit('sidebar:update');
  }

  private getCorrespondingHash(): string {
    let hash = '';

    if (this.$route.hash !== '') {
      const hashArray = urlHashToArray(this.$route.hash);
      hash = hashArray[hashArray.length - 1];
    }

    return hash;
  }

  private getDossierId(): string|number|undefined {
    if (this.$route.hash !== '') {
      const correspondingHash = this.getCorrespondingHash();
      if (correspondingHash.includes('detail-')) {
        return correspondingHash.replace('detail-', '');
      }
    }
    if (this.$route.params?.dossierId !== undefined) {
      return this.$route.params.dossierId;
    }

    if (this.dossierId) {
      return this.dossierId;
    }
    return undefined;
  }

  protected executeDossierTypeListAction(action: DossierTypeBulkAction|DossierTypeListBulkAction, actionType: string): void {
    let operation: GraphqlOperations;
    const dossierId = this.getDossierId();
    const variables: GraphqlVariables = {
      actionId: action.id,
      selectedIds: this.selectedIds,
      context: this.getCorrespondingHash(),
    };

    switch (actionType) {
      case 'dossier-type-list-action':
        operation = 'dossier-type-list-handle-action';
        if (dossierId) {
          operation = 'dossier-type-list-handle-action-by-block-id';
          variables.dossierId = dossierId;
          variables.navigationSlug = this.getNavigationSlug();
          variables.dossierTypeSlug = this.getDossierTypeTag();
        }
        break;
      case 'Action':
        operation = 'dossier-type-handle-bulk-action';
        variables.navigationSlug = this.getNavigationSlug();
        variables.dossierTypeSlug = this.getDossierTypeTag();
        break;
      case 'Block-action':
        operation = 'execute-dossier-block-action';
        variables.blockId = this.blockId ?? 0;
        variables.dossierId = this.getDossierId() ?? 0;
        variables.navigationSlug = this.getNavigationSlug();
        variables.dossierTypeSlug = this.getDossierTypeTag();
        break;
      case 'workflow-bulk-action':
        operation = 'dossier-type-list-handle-action-by-block-id';
        variables.dossierId = this.$route.params.dossierId;
        break;
      default:
        operation = 'dossier-type-list-handle-action-by-block-id';
    }

    this.executeAction(operation, variables, action.metadata?.forceRerender ?? true)
      .then(() => {
        this.$emit('rerender');
      });
  }

  private executeAction(graphqlOperation: GraphqlOperations, variables: GraphqlVariables, forceRerenderOnSuccess: boolean): Promise<void> {
    this.loading = true;

    return import(`@/graphql/mutations/${graphqlOperation}`)
      .then(({ default: mutation }) => this.$apollo.mutate({
        mutation,
        variables,
      }))
      .then((response) => {
        if (response.data.result?.successful === true || response.data.successful === true) {
          if (response.data.result?.metadata?.attachment !== undefined) {
            downloadBase64(response.data.result.metadata.attachment);
          }
          Message.success(this.$t('add.notification'));
          if (forceRerenderOnSuccess) {
            eventBus.$emit('initializeForceRerender');
          }
        } else {
          Message.error(this.$t('generic.error.occurred'));
        }
      })
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        throw error;
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
