interface Base64File {
  name: string,
  base64Content: string,
}

export default async function downloadBase64(file: Base64File): Promise<void> {
  const base64 = file.base64Content;
  const fileName = file.name;
  const fileType = 'image/jpg';

  if (base64 === undefined && fileName === undefined) {
    throw Error('No file found to download');
  }

  return fetch(`data:${fileType};base64,${base64}`)
    .then((res) => res.blob())
    .then((blobImage) => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blobImage);
      link.download = fileName;
      link.click();
      URL.revokeObjectURL(link.href);
    });
}
