

import { Vue, Component, Prop } from 'vue-property-decorator';
import DossierTypeList from '@/components/DossierTypeList.vue';
import { FetchPolicy } from 'apollo-client';
import Message from '@/components/mixins/Message.vue';
import BaseCard from '@/components/commonComponents/BaseCard.vue';
import ListBulkActions from '@/components/commonComponents/ListBulkActions.vue';
import DataTable from '@/components/DataTable/DataTable.vue';
import ListAddFields from '@/components/commonComponents/ListAddFields.vue';
import { isEmptyArray } from '@/helpers/arrayHelpers';
import LoadingTracker from '@/helpers/LoadingTracker';
import DataTableHandler from '@/components/DataTable/DataTable';

@Component({
  components: {
    ListAddFields,
    DataTable,
    ListBulkActions,
    BaseCard,
    DossierTypeList,
  },
  methods: {
    isEmptyArray,
  },
})
export default class DashboardDossierTypeList extends Vue {
  @Prop({ required: true })
  private readonly blockId!: number;

  @Prop({ required: true })
  private readonly title!: string;

  private totalResultCounter = '';

  private dataTable = new DataTableHandler('DashboardDossierTypeList');

  private loadingTracker = new LoadingTracker();

  private loading = false;

  private hasAddFields = false;

  private hasExport = false;

  private dossierTypeSlug: string | null = null;

  private navigationSlug: string | null = null;

  mounted(): void {
    this.fetchData();
  }

  private fetchData(fetchPolicy: FetchPolicy = 'no-cache'): void {
    this.loading = true;

    const variables = {
      ...this.dataTable.getQueryVariables(),
      blockId: this.blockId,
    };

    import('@/graphql/queries/dashboard-list-block')
      .then(({ default: query }) => this.$apollo.query({
        query,
        variables,
        fetchPolicy,
      }))
      .then((response) => {
        this.dossierTypeSlug = response.data.list.dataGridInfo.metadata['module-settings'].dossierTypeSlug;
        this.navigationSlug = response.data.list.dataGridInfo.metadata['module-settings'].navigationSlug;
        this.dataTable.handleData(response.data);
        this.hasAddFields = response.data.list.dataGridInfo.metadata['list-settings']?.quick_add ?? false;
        this.hasExport = response.data.list.dataGridInfo.metadata['list-settings']?.export ?? false;

        this.totalResultCounter = `(${this.dataTable.total})`;
      })
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        throw error;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  protected exportFile(): void {
    this.loading = true;

    const variables = {
      ...this.dataTable.getQueryVariables(),
      navigationSlug: this.navigationSlug,
      dossierTypeSlug: this.dossierTypeSlug,
    };
    import('@/graphql/queries/export-dossier-type-list-by-navigation-slug')
      .then(({ default: query }) => this.$apollo.query({
        query,
        variables,
      }))
      .then((response) => {
        const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response.data.export.data}`;
        const downloadLink = document.createElement('a');
        const fileName = response.data.export.name;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
